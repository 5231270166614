



































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Region } from '@/store/modules/region'
import { EventBus } from '@/utils/event-bus'

@Component
export default class ManageRegion extends Vue {
  @Prop() region!: Region
  dismissSecs: number = 5
  dismissCountDown: number = 0
  countDownChanged (dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown
  }

  get hasMarkets () {
    return this.region.markets && this.region.markets.length > 0
  }

  async modalShown () {
    await this.$nextTick()
    await this.$store.dispatch('loadMarkets')
  }

  async deleteRegion () {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to delete?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      EventBus.$emit('show-loader')
      await this.$store.dispatch('deleteRegion', this.region)
      this.regionUpdated()
      EventBus.$emit('hide-loader')
    })
  }

  @Emit('regionUpdated')
  regionUpdated (hide: boolean = false) {
    this.dismissCountDown = this.dismissSecs
    return hide ? '' : this.region.key
  }

  async saveChanges () {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to save changes?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      EventBus.$emit('show-loader')
      await this.$store.dispatch('saveRegion', this.region)
      this.regionUpdated()
      EventBus.$emit('hide-loader')
    })
  }
}
