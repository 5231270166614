








































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { Market } from '@/store/modules/market'
import { EventBus } from '@/utils/event-bus'
import { Region } from '@/store/modules/region'

@Component
export default class ManageMarket extends Vue {
  @Prop() market!: Market
  dismissSecs: number = 5
  dismissCountDown: number = 0
  selectedRegionKey: string | null = null
  countDownChanged (dismissCountDown: number) {
    this.dismissCountDown = dismissCountDown
  }

  get regionOptions () {
    return this.$store.getters.getRegions
  }

  async modalShown () {
    await this.$nextTick()
    this.selectedRegionKey = this.market.region ? this.market.region.key : null
  }

  async deleteMarket () {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to delete?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      EventBus.$emit('show-loader')
      await this.$store.dispatch('deleteMarket', this.market)
      this.marketUpdated()
      EventBus.$emit('hide-loader')
    })
  }

  @Emit('marketUpdated')
  marketUpdated (hide: boolean = false) {
    this.dismissCountDown = this.dismissSecs
    return hide ? '' : this.market.key
  }

  async saveChanges () {
    this.$bvModal.msgBoxConfirm('Are you sure you wish to save changes?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      EventBus.$emit('show-loader')
      if (this.selectedRegionKey) {
        this.market.region = {
          key: this.selectedRegionKey
        } as Region
      }
      await this.$store.dispatch('saveMarket', this.market)
      this.marketUpdated()
      EventBus.$emit('hide-loader')
    })
  }
}
