






















































































import { Component, Vue } from 'vue-property-decorator'
import { hasPermissions } from '@/utils/auth'
import { Market } from '@/store/modules/market'
import ManageRegionModal from './ManageRegion.modal.vue'
import ManageMarketModal from './ManageMarket.modal.vue'
import { Region } from '@/store/modules/region'

@Component({
  components: {
    ManageRegionModal,
    ManageMarketModal
  }
})
export default class MarketsAdminDashboard extends Vue {
  hasPermissions = hasPermissions
  helpText: string = 'Here you can manage markets and regions.'
  breadcrumbItems: any[] = [
    {
      text: 'Admin',
      to: { name: 'admin' }
    },
    {
      text: 'Manage markets',
      to: { name: 'manage-markets' }
    }
  ]

  regionTableFields: any[] = [
    {
      key: 'name',
      label: 'Name',
      sortable: true
    },
    {
      key: 'marketNames',
      label: 'Markets',
      sortable: true
    },
    {
      key: 'show_details',
      label: 'Options'
    }
  ]

  marketTableFields: any[] = [
    {
      key: 'name',
      label: 'Name',
      sortable: true
    },
    {
      key: 'regionName',
      label: 'Region',
      sortable: true
    },
    {
      key: 'show_details',
      label: 'Options'
    }
  ]

  get markets () {
    return this.$store.getters.getMarkets.map((market: Market) => {
      return {
        key: market.key,
        name: market.name,
        regionName: market.region ? market.region.name : ''
      }
    })
  }

  get regions () {
    return this.$store.getters.getRegions.map((region: Region) => {
      return {
        key: region.key,
        name: region.name,
        marketNames: region.markets.map((m: Market) => m.name).join(', ')
      }
    })
  }

  activeMarket: Market = this.$store.getters.getMarketByKey()
  activeRegion: Region = this.$store.getters.getRegionByKey()

  async mounted () {
    await this.$nextTick()
    await this.$store.dispatch('loadRegions')
    await this.$store.dispatch('loadMarkets')
  }

  openManageRegionModal (key: string) {
    this.activeRegion = this.$store.getters.getRegionByKey(key)
    this.$bvModal.show('manage-region-modal')
  }

  manageRegionModalClosed () {
    this.$store.dispatch('loadRegions')
    this.$store.dispatch('loadMarkets')
  }

  async regionUpdated (regionKey: string) {
    await this.$store.dispatch('loadRegions')
    if (regionKey) {
      this.activeRegion = this.$store.getters.getRegionByKey(regionKey)
    } else {
      this.$bvModal.hide('manage-region-modal')
    }
  }

  async addNewRegion () {
    this.$bvModal.msgBoxConfirm('Create new region?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      const regionToSave = {
        name: 'New region'
      }
      const newRegion: Region = await this.$store.dispatch('addRegion', regionToSave)
      await this.$store.dispatch('loadRegions')
      this.activeRegion = await this.$store.getters.getRegionByKey(newRegion.key)
      this.$bvModal.show('manage-region-modal')
    })
  }

  openManageMarketModal (key: string) {
    this.activeMarket = this.$store.getters.getMarketByKey(key)
    this.$bvModal.show('manage-market-modal')
  }

  manageMarketModalClosed () {
    this.$store.dispatch('loadMarkets')
    this.$store.dispatch('loadRegions')
  }

  async marketUpdated (marketKey: string) {
    await this.$store.dispatch('loadMarkets')
    if (marketKey) {
      this.activeMarket = this.$store.getters.getMarketByKey(marketKey)
    } else {
      this.$bvModal.hide('manage-market-modal')
    }
  }

  async addNewMarket () {
    this.$bvModal.msgBoxConfirm('Create new market?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(async value => {
      if (!value) {
        return
      }
      const marketToSave = {
        name: 'New market'
      }
      const newMarket: Market = await this.$store.dispatch('addMarket', marketToSave)
      await this.$store.dispatch('loadMarkets')
      this.activeMarket = await this.$store.getters.getMarketByKey(newMarket.key)
      this.$bvModal.show('manage-market-modal')
    })
  }
}
